import request from '../request'
export default {
    /**
     * 门店订单列表
     * @param {*} data 
     * @returns 
    */
    measurementsShopsList(data) {
        return new Promise((resolve, reject) => {
            request.post('/measurements/shopsList',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 导出门店订单列表
     * @param {*} data 
     * @returns 
    */
    getMeasurementsShopsList(data) {
        return new Promise((resolve, reject) => {
            request.get('/measurements/shopsList',data,{
                responseType:'arraybuffer',
                is_file:true,
                // headers:{
                //     'Content-Type':'application/vnd.ms-excel;charset=UTF-8'
                // }
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
}