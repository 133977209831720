import request from '../request'
export default {
    /**
     * 工厂列表
     * @param {*} data 
     * @returns 
     */
    factoryList(data) {
        return new Promise((resolve, reject) => {
            request.post('/factory/list',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 新增工厂
     * @param {*} data 
     * @returns 
     */
    factoryCreate(data) {
        return new Promise((resolve, reject) => {
            request.post('/factory/create',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 工厂内容修改
     * @param {*} data 
     * @returns 
     */
    factoryEdit(data) {
        return new Promise((resolve, reject) => {
            request.post('/factory/edit',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 工厂内容修改
     * @param {*} data 
     * @returns 
     */
    factoryChangStatus(data) {
        return new Promise((resolve, reject) => {
            request.post('/factory/changStatus',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 创建门店
     * @param {*} data 
     * @returns 
     */
    shopsCreate(data) {
        return new Promise((resolve, reject) => {
            request.post('/shops/create',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 编辑门店
     * @param {*} data 
     * @returns 
     */
    shopsEdit(data) {
        return new Promise((resolve, reject) => {
            request.post('/shops/edit',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 门店列表
     * @param {*} data 
     * @returns 
     */
    shopsList(data) {
        return new Promise((resolve, reject) => {
            request.post('/shops/list',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 门店状态修改
     * @param {*} data 
     * @returns 
    */
    shopsChangStatus(data) {
        return new Promise((resolve, reject) => {
            request.post('/shops/changStatus',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 平台订单列表
     * @param {*} data 
     * @returns 
    */
    measurementsList(data) {
        return new Promise((resolve, reject) => {
            request.post('/measurements/list',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 下载平台订单列表
     * @param {*} data 
     * @returns 
    */
    getMeasurementsList(data) {
        return new Promise((resolve, reject) => {
            request.get('/measurements/list',data,{
                responseType:'arraybuffer',
                is_file:true,
                // headers:{
                //     'Content-Type':'application/vnd.ms-excel;charset=UTF-8'
                // }
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 配置config
     * @param {*} data 
     * @returns 
    */
    configSet(data) {
        return new Promise((resolve, reject) => {
            request.post('/config/configSet',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取config
     * @param {*} data 
     * @returns 
    */
    configGet(data) {
        return new Promise((resolve, reject) => {
            request.post('/config/configGet',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取地址列表
     * @param {*} data 
     * @returns 
    */
    logisticsAddressList(data) {
        return new Promise((resolve, reject) => {
            request.post('/logisticsAddress/list',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 删除地址
     * @param {*} data 
     * @returns 
    */
    delLogAddress(data) {
        return new Promise((resolve, reject) => {
            request.post('/logisticsAddress/delLogAddress',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取地址详情
     * @param {*} data 
     * @returns 
    */
    getAddressDetail(data) {
        return new Promise((resolve, reject) => {
            request.post('/logisticsAddress/getOne',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 编辑地址
     * @param {*} data 
     * @returns 
    */
    opLogAddress(data) {
        return new Promise((resolve, reject) => {
            request.post('/logisticsAddress/opLogAddress',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 物流订单列表
     * @param {*} data 
     * @returns 
    */
    logisticsList(data) {
        return new Promise((resolve, reject) => {
            request.post('/logistics/list',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 上传提货单
     * @param {*} data 
     * @returns 
    */
    differentialBill(data) {
        return new Promise((resolve, reject) => {
            request.post('/logistics/differentialBill',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 上传签收单
     * @param {*} data 
     * @returns 
    */
    acceptanceBill(data) {
        return new Promise((resolve, reject) => {
            request.post('/logistics/acceptanceBill',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 发送邮箱
     * @param {*} data 
     * @returns 
    */
    sendEmail(data) {
        return new Promise((resolve, reject) => {
            request.post('/measurements/sendEmail',data,{
                header:{
                    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    
    
}