import calculation from './calculation'

// 转换年月日时分秒
function formatFullTime(timestamp){
    var date = new Date(timestamp);
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1) + '-';
    var D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate())+ ' ';
    var h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours())+ ':';
    var m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
    var s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();
    return Y+M+D+h+m+s;
}
// 转换年月日
function formatFullDate(timestamp){
    if(!timestamp){
        return ''
    }
    var date = new Date(timestamp);
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1) + '-';
    var D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate());
    return Y+M+D;
}
// 转换时分秒
function formatTime(timestamp){
    if(!timestamp){
        return ''
    }
    var date = new Date(timestamp);
    var h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours())+ ':';
    var m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
    var s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();
    return h+m+s;
}
/**
  * 转换年月日时分秒
  */
 function customFormatFullTime(timestamp,format='YYYY-MM-DD h:m:s'){
    var date = new Date(timestamp);
    var Y = date.getFullYear();
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1);
    var D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate());
    var h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours());
    var m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes());
    var s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();
    return format.replace('YYYY',Y).replace('MM',M).replace('DD',D).replace('h',h).replace('m',m).replace('s',s)
}
function toInteger(n){
    return Math.abs(parseInt(n))
}
function toDecimal(n,zero_num=4){
    let value = '1'
    for(let i =0;i<zero_num;i++){
        value+='0'
    }
    value = parseInt(value)
    return calculation.accDiv(parseInt(calculation.accMul(parseFloat(n),value)),value)
}
function toXls(content){
    const blob = new Blob([content])
    if ('download' in document.createElement('a')) { // 非IE下载
        const elink = document.createElement('a')
        const fileNamex = `${Date.now()}.xls`
        elink.download = fileNamex
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
    } else { // IE10+下载
        navigator.msSaveBlob(blob, fileNamex)
    }
}
function dateAddDays(dateStr,dayCount){
    var tempDate=new Date(dateStr);//把日期字符串转换成日期格式
    var resultDate=new Date((tempDate/1000+(86400*dayCount))*1000);//增加n天后的日期
    return formatFullDate(resultDate);
}
/**
 * 手机号是否可用
 * @param {int}} $poneInput 
 * @returns 
 */
function isPoneAvailable($poneInput) {
    var myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test($poneInput)) {
        return false;
    } else {
        return true;
    }
}
/**
 * 重构部门列表
 * @param {*} list 
 * @returns 
 */
 function reconsitutionDepartmentList(data){
    return [
        {
            department_id:0,
            department_name:data.company.name,
            id:0,
            children:data.list?data.list:[]
        }
    ]
}

/*
 * 生成随机数时间戳
*/
function stampRandom() {
    var a = Math.random, b = parseInt;
    return Number(new Date()).toString() + b(10 * a()) + b(10 * a()) + b(10 * a());
}

/*
 * 时分转时间戳
*/
function hmToTimestamp(time) {
    let hour = time.split(':')[0]
    let min = time.split(':')[1]
    return Number(hour*6000*60)+Number(min*6000)
}
export {
    formatFullTime,
    formatFullDate,
    formatTime,
    toInteger,
    toDecimal,
    toXls,
    dateAddDays,
    isPoneAvailable,
    reconsitutionDepartmentList,
    customFormatFullTime,
    stampRandom,
    hmToTimestamp
}