import request from '../request'
export default {
    /**
     * 登录
     * @param {d} data 
     * @returns 
    */
    indexLogin(data) {
        return new Promise((resolve, reject) => {
            request.post('/Index/login',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 订单详情
     * @param {d} data 
     * @returns 
    */
    orderDetail(data) {
        return new Promise((resolve, reject) => {
            request.post('/measurements/orderDetail',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 量尺完成内容
     * @param {d} data 
     * @returns 
    */
    mOrderDone(data) {
        return new Promise((resolve, reject) => {
            request.post('/measurements/mOrderDone',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取阿里oss授权
     * @param {*} data 
     * @returns 
     */
    getSTS(data) {
        return new Promise((resolve, reject) => {
            request.post('/oss/getSTS',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * wx指定消息推送
     * @param {*} data 
     * @returns 
     */
    templatePush(data) {
        return new Promise((resolve, reject) => {
            request.post('/wx/templatePush',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}